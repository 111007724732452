<div class="ng-header">
    <div class="ng-header-left left">
        <div class="toggle-menu-button left">
            <a class="icon" (click)="toggleMenu()"><i class="fa fa-bars"></i></a>
        </div>
        <div class="logo right">
            <!-- <img src="assets/images/bg_logo.svg" class="logo-large"  style="width: 100px;float:left;margin-left: 15%;"> -->
            <img src="assets/images/SRHT_Logo_Color1.svg" class="logo-large">
            <img src="assets/images/SRHT_Logo_Color1.svg" class="logo-small">

        </div>
    </div>
    <div class="ng-header-right right">
        <div class="ng-header-right-item right">
            <a class="icon" pTooltip="Log Out" (click)="logout()"><i class="fa fa-sign-out-alt"></i></a>
        </div>
    </div>
</div>


	<!-- notifications sidebar -->
    <p-sidebar [(visible)]="displayNotifications" position="right">
        <div *ngFor="let notification of notifications; let i=index" class="notification-message">
            <div><b>Message: </b><i [innerHtml]="notification.message"></i></div>
            <div><i>Created On: </i><i [innerHtml]="notification.createdOn | date:'dd/MM/yyyy'"></i></div>
        </div>
    </p-sidebar>

<!-- theme chooser overlay panel -->
<p-overlayPanel #themeChooser>
    Select Theme:
    <div class="ui-g">
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-teal')" style="background-color: #427976;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-orange')" style="background-color: #ffa43b;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-light')" style="background-color: #cccccc"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-purple')" style="background-color: #3e3eb1"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-Turquoise')" style="background-color:#04838f;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-green')" style="background-color: #1e8455;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-blue')" style="background-color: #007dbc;"></button>
        </div>
        <div class="ui-g-3">
            <button pButton type="button" (click)="selectTheme('theme-black')" style="background-color: #000;"></button>
        </div>
    </div>
</p-overlayPanel>