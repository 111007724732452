<div class="row error-box">
    <div class="col-sm-12">
        <div class="text-center">
            <h1> Error 401</h1>
            <h2 class="font-xl">
                <strong>
                    <i class="fa fa-fw fa-exclamation-triangle fa-lg"></i> Access <u>Denied</u>
                </strong>
            </h2>
            <p>
                <!-- The page you requested could not be Loaded, either contact your Admini or try again. -->
                You don't have Permission to access this Page! Please Contact the Administrator
            </p>
            <p>
                Use your browsers <b>Back</b> button to navigate to the page you have prevously come from.
            </p>
        </div>
    </div>
</div>