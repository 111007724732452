// angular default
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Prime NG
import { MessageService } from 'primeng/api';
// app related
import { AppComponent } from 'src/app/app.component';
import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';
import { AppRoutingModule } from 'src/app/app.routing.module';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';
import { MenuComponent } from 'src/app/shared/layout/menu/menu.component';
import { HeaderComponent } from 'src/app/shared/layout/header/header.component';
import { FooterComponent } from 'src/app/shared/layout/footer/footer.component';
import { UserIdleModule } from 'angular-user-idle';
import { HttpClientModule, HttpClient ,HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppCommonModule } from 'src/app/app.common.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './core/services/auth.service';
import { ApiService } from './core/services/api.service';
import { ServiceGateway } from './core/services/servicegatewayNew.service';
import { JwtModule } from "@auth0/angular-jwt";
import {MsalModule} from "@azure/msal-angular";
import { MsalInterceptor} from "@azure/msal-angular";
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { HomeComponent } from './features/home/home.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return sessionStorage.getItem("access_token");
}

export function loggerCallback(logLevel, message, piiEnabled) {
  // console.log("client logging" + message);
}

export const protectedResourceMap:[string, string[]][]=[['https://graph.microsoft.com/v1.0/me', ['user.read']] ];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent
   
  ],
  imports: [
    BrowserModule,   
    BrowserAnimationsModule,   
    AppRoutingModule,
    UserIdleModule.forRoot({ idle: 300, timeout: 1, ping: null }),
    HttpClientModule,
    PdfJsViewerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["example.com"],
        blacklistedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    AppCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }   
    }),
    MsalModule.forRoot({
      clientID: 'b38c3680-6709-463a-8e41-a41fdcd2e3d0',
      authority: "https://login.microsoftonline.com/bf637328-734f-41bf-b8bf-399debb45cf3/",
      validateAuthority: true,
      redirectUri : window.location.origin + "/home",
      cacheLocation : "sessionStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
      consentScopes: [ "user.read", "openid", "profile"],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap: protectedResourceMap,
      logger: loggerCallback,
      piiLoggingEnabled: true
    }),  
  ],
  exports: [TranslateModule],
  providers: [
    MessageService,
    AuthGuard,
    AuthService, 
    ServiceGateway,
    ApiService,
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true}
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }