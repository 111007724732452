export var CONFIG = {
    Local_API_Url: "http://localhost:5003/api/",
    Prod_API_Url: "https://srht-ms-dms-api.azurewebsites.net/api/",
    PDF_URL: "http://bg-doc.azurewebsites.net/",
    ContentType: 'application/json',
    isStaticLogin : true,
    project_code : "e5125962-e6ef-4d45-a224-4b8ab58bae91"
};    
  
export var main_url: any = {
    api_url: CONFIG.Prod_API_Url,
    blob_url: CONFIG.PDF_URL   
}                                      
                      
export var API_URLS: any = {
    Generic_api_url : main_url.api_url,     
    Auth_url : "auth/login",  
    GetAPIValues: main_url.api_url + "values",
    GetAuthToken: main_url.api_url + "Authentication/Request",

    coversheet_url : "CoverSheets",
    documentupload_url : "DocumentUpload",
    DataSouceUpload : main_url.api_url + "DocumentUpload",
    MultipleDataSourceUpload : main_url.api_url + "DocumentUpload/UploadMultipleFiles",
    DMSMultipleDataSourceUpload : main_url.api_url + "DocumentUpload/UploadMultipleDMSFiles",
    MergePDFFiles : main_url.api_url + "DocumentUpload/MergeAndUploadFile",
    PreviewPDFFile : main_url.api_url + "DocumentUpload/DownloadPDF/",
    GetPDFBlob : main_url.blob_url + "index.html",
    GetCoverSheetScans : main_url.api_url + "datasource/CoverSheetScans",
    Clients_url : "clients",   
    Filetypes_url : "filetypes",
    Doctype_url:"Doctypes",
    Indexes_url:"Indexes",   
    Departments_url:" Departments", 
    pipeline_url: "DMSPipelines",
    pipelinesource_url: "PipeLineSource",

    Users_url:"UsersInfo",
    Roles_url:"RolesInfo",      
    Permissions_url:"PermissionsInfo",
    RolePermissions_url:"RolePermissions",
    Apps_url: "Projects",
    Modules_url: "ModulesInfo",
    Parent_url: "ParentModules",
    Perm_url:"Permission",

    // Users
    UsersInfo: 'UserGroups'

}    

export const HTTP_VERBS : any = {
    Get : "get",
    GetById : "getById",   
    Post : "post",
    Update : "put",
    Delete : "delete",
    Post2 : "post2",
    Get2 : "get2"
}   
   
            