import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'primeng/api';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { MsalService } from '@azure/msal-angular';
import { API_URLS, CONFIG, HTTP_VERBS } from '../../config/config';
import { config } from 'rxjs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit {

  userName: string = "";
  loaderEnable: boolean = false;
  appCode: string = CONFIG.project_code;
  isPermissionExists: boolean = true;


  constructor(private messageService: MessageService,
    private permissionsService: PermissionsService,
    private msalService: MsalService, private authService: AuthService) { }

  ngOnInit() {

    this.loaderEnable = true;

    let decodedToken = this.authService.getTokenObjects();
    if (decodedToken != null && decodedToken.preferred_username != null && decodedToken.preferred_username != undefined && decodedToken.preferred_username != "") {
      this.userName = decodedToken.preferred_username;
      var obj = {
        emailId: decodedToken.preferred_username,
        appCode: this.appCode
      }

      this.permissionsService.projectPermission(obj).subscribe((data) => {
        if (data != null  && data.length > 0) {
          this.isPermissionExists = true;
          this.loaderEnable = false;
        }
        else{
          this.isPermissionExists = false;
          this.loaderEnable = false;
        }
      });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Session Expired!', sticky: false });
      this.loaderEnable = false;
      this.msalService.logout();
    }
  }
}
