// #docregion
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import 'rxjs/add/observable/of';
import { ApiService } from './api.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthService {

    getAuthResponse(): any {
        return sessionStorage.getItem('auth_info');
    }

    constructor(private router: Router, private apiService: ApiService) {
    }

    getTokenObjects(): any {

        let authResponse: any = this.getAuthResponse();
        if (this.online(authResponse)) {
            let json_data = JSON.parse(authResponse);
            let decodedToken = jwt_decode(json_data._token);
            return decodedToken;
        }
    }

    getExpirationTime(token: any) {
        var access_token: any = jwt_decode(token);
        if (access_token != undefined && access_token != null && access_token.exp) {
            return +access_token.exp;
        }
        else {
            return 0;
        }
    }

    online(session: any) {
        let json_session = JSON.parse(session);
        let today = moment().utc().format('X');
        let currentTime = +today;
        if (json_session && json_session._token) {
            let expireTime = this.getExpirationTime(json_session._token);
            let sessionAndTokenValid: boolean = json_session && json_session._token && expireTime > currentTime;
            if (sessionAndTokenValid == null || sessionAndTokenValid == false)
                return false;
            else
                return sessionAndTokenValid;
        }
    }
}

