import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { ServiceGateway } from './servicegatewayNew.service';
import { API_URLS, CONFIG, HTTP_VERBS } from '../../config/config';

@Injectable()
export class ApiService {

    constructor(private serviceGateWay: ServiceGateway) { }


    // Generic API Call
    public GenericAPICall(apiAction, id, data, method): Observable<any> {
        if (method === HTTP_VERBS.Get) {
            return this.serviceGateWay.get(API_URLS.Generic_api_url + apiAction);
        } else if (method === HTTP_VERBS.GetById) {
            return this.serviceGateWay.get(API_URLS.Generic_api_url + apiAction + '/' + id);
        } else if (method === HTTP_VERBS.Post) {
            return this.serviceGateWay.post(API_URLS.Generic_api_url + apiAction, 'application/json', data);
        } else if (method === HTTP_VERBS.Update) {
            return this.serviceGateWay.put(API_URLS.Generic_api_url + apiAction + '/' + id, 'application/json', data);
        } else if (method === HTTP_VERBS.Delete) {
            return this.serviceGateWay.post(API_URLS.Generic_api_url + apiAction + '/' + id, 'application/json', null);
        }
        else if(method === HTTP_VERBS.Post2){
            return this.serviceGateWay.postwithouttoken(API_URLS.Generic_api_url + apiAction, data);
        } 
        else if(method === HTTP_VERBS.Get2){
            return this.serviceGateWay.getwithouttoken(API_URLS.Generic_api_url + apiAction);
        } 
    }
}
