import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomMenuItem } from '../models/menu-item.model';

@Injectable({
    providedIn: 'root',
})
/**
 * menu data service
 */
export class MenuDataService {

    public toggleMenuBar: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public wizardStepBar: BehaviorSubject<any> = new BehaviorSubject<number>(0);

    public toggleMenuClick: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // getMenuList(): CustomMenuItem[] {
    //     return [
    //         {  
    //             Label: 'Documents' , Icon: 'fa-file', RouterLink: '/datasource/documents/documentlookup', Childs: [
    //                 {
    //                     Label: 'Document Lookup', Icon: 'fa-search', RouterLink: '/datasource/documents/documentlookup', Childs: null, IsChildVisible: false
    //                 },
    //                 {
    //                     Label: 'My Documents', Icon: 'fa-folder', RouterLink: '/datasource/documents/documentlookup', Childs: null, IsChildVisible: false
    //                 },
    //                 {
    //                     Label: 'My Projects', Icon: 'fa-tasks', RouterLink: '/datasource/documents/documentlookup', Childs: null, IsChildVisible: false
    //                 },
    //                 {
    //                     Label: 'Coversheet Generator', Icon: 'fa-barcode', RouterLink: '/datasource/documents/coversheet', Childs: null, IsChildVisible: false
    //                 },
    //                 // {
    //                 //     Label: 'Document Upload', Icon: 'fa-clone', RouterLink: '/datasource/documents/documentupload', Childs: null, IsChildVisible: false
    //                 // },  
    //                 {
    //                     Label: 'Document Trash', Icon: 'fa-trash', RouterLink: '/datasource/documents/trash', Childs: null, IsChildVisible: false
    //                 },
    //             ], IsChildVisible: false
    //         },
    //         {
    //             Label: 'Settings', Icon: 'fa-cog', RouterLink: '/datasource/settings', Childs: [
    //                 {
    //                     Label: 'Clients', Icon: 'fa-user', RouterLink: '/datasource/settings/clients', Childs: null, IsChildVisible: false
    //                 },
    //                 {
    //                     Label: 'Departments', Icon: 'fa-list', RouterLink: '/datasource/settings/departments', Childs: null, IsChildVisible: false
    //                 },
    //                 {
    //                     Label: 'File Types', Icon: 'fa-list', RouterLink: '/datasource/settings/filetypes', Childs: null, IsChildVisible: false
    //                 },
    //                 {
    //                     Label: 'Doc Types', Icon: 'fa-folder-open', RouterLink: '/datasource/settings/doctypes', Childs: null, IsChildVisible: false
    //                 },  
    //                 {
    //                     Label: 'Indexes', Icon: 'fa-bookmark', RouterLink: '/datasource/settings/indexes', Childs: null, IsChildVisible: false
    //                 }
    //             ], IsChildVisible: false
    //         },
    //     ];
    // }
}