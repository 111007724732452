<div class="row error-box1">
    <div class="col-sm-12">
        <div  style="margin-top:50px ;">
            <h3  style="color: #007dbc;"> Welcome '{{userName | slice:0:-12 }}' </h3>
            <p style="color: #151414 !important;">
                Explore the Document Management System which helps to manage your documents at your fingertips!
            </p>
            <p style="color:red;" *ngIf="!isPermissionExists">
                <i class="pi pi-exclamation-triangle"></i>   Oops!! You have No Access to Any Modules! Please Contact Admin.
            </p>
        </div> 
    </div>
</div>   

<div class="np-loader" *ngIf="loaderEnable">
    <p-progressSpinner strokeWidth="2"></p-progressSpinner>
</div>  
