import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { ServiceGateway } from '../servicegatewayNew.service';
import { API_URLS, CONFIG, HTTP_VERBS } from '../../../config/config';


@Injectable({
    providedIn: 'root',
})
export class PermissionsService {

    constructor(private serviceGateWay :ServiceGateway ) {
    }

    public modulePermission(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.Perm_url +'/getPermByModCode', 'application/json', data);
    }

    public projectPermission(data): Observable<any> {
        return this.serviceGateWay.post(API_URLS.Generic_api_url + API_URLS.Perm_url +'/getPermByProjCode', 'application/json', data);
    }

    public getModuleCode(slug: string) {
        return this.serviceGateWay.get(API_URLS.Generic_api_url + API_URLS.Perm_url + '/' + slug);
    }
}
