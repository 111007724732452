import { Component, OnInit } from '@angular/core';
import { MenuDataService } from 'src/app/core/services/menu-data.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { RouteStateService } from 'src/app/core/services/route-state.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { CustomMenuItem } from 'src/app/core/models/menu-item.model';
import * as _ from 'lodash';
import { API_URLS, CONFIG, HTTP_VERBS } from '../../config/config';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.css']
})
export class LayoutComponent implements OnInit {

  isMenuVisible: boolean;
  items: CustomMenuItem[];
  userName : string = "";
  appCode: string = CONFIG.project_code;
  loaderEnable: boolean = false;
  routerLink : string = "";

  constructor(private menuDataService: MenuDataService,
    private applicationStateService: ApplicationStateService,private permissionsService: PermissionsService,private routeStateService: RouteStateService,private authService: AuthService) {
  }

  ngOnInit() {
    var that = this;
    this.menuDataService.toggleMenuBar.subscribe(function (data: any) {
      if (data && data != null) {
        that.isMenuVisible = !that.isMenuVisible;
      }
    });

    if (this.applicationStateService.getIsMobileResolution()) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
  }

  navigate(){
    this.routeStateService.add("Error 401 UnAuthroized", "/datasource/documents/documentlookup", null, false);
  }

  ngOnDestroy() {
    this.menuDataService.toggleMenuBar.observers.forEach(function (element) { element.complete(); });
  }

}
