import { Component, OnInit } from '@angular/core';
import { RouteStateService } from 'src/app/core/services/route-state.service';
import { SessionService } from 'src/app/core/services/session.service';
import { CustomMenuItem } from 'src/app/core/models/menu-item.model';
import { MenuDataService } from 'src/app/core/services/menu-data.service';
import { ApplicationStateService } from 'src/app/core/services/application-state.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import {MsalService} from '@azure/msal-angular';
import { API_URLS, CONFIG, HTTP_VERBS } from '../../../config/config';

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.css']
})
export class MenuComponent implements OnInit {

    items: CustomMenuItem[];
    menuItems: CustomMenuItem[] = [];
    selectedItem: string;
    visible: boolean = true;

    tokenObjectId: string = "";
    appCode: string = CONFIG.project_code;
    //appCode : string = null;
    permissionArr: any = [];
    loaderEnable: boolean = false;

    constructor(private routeStateService: RouteStateService,
        private sessionService: SessionService,
        private menuDataService: MenuDataService,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private messageService: MessageService,
        private msalService: MsalService,
        private applicationStateService: ApplicationStateService) { }

    ngOnInit() {
        this.loaderEnable = true;
        //this.items = this.menuDataService.getMenuList();
        //this.getAllMenuByPermissions();
        let decodedToken = this.authService.getTokenObjects();
        
        if(decodedToken != undefined &&  decodedToken != null && decodedToken.preferred_username != null && decodedToken.preferred_username != undefined && decodedToken.preferred_username != ""){
            let homeObj: any =[];
            homeObj  = [ {
                   childs: null,
                   icon: "pi pi-home",
                   isChildVisible: true,
                   label: "Home",
                   routerLink: "/home",
           }]
           this.items = [];
           for(var home of homeObj){
               this.items.push(home);
           }

           this.selectedItem = "Home";
            var obj = {
                emailId : decodedToken.preferred_username,
                appCode : this.appCode  
            }
            this.permissionsService.projectPermission(obj).subscribe((data) => {
                if(data != null && data.length > 0){
                    for(var menuObj of data){
                        this.items.push(menuObj);
                    }
                    this.loaderEnable = false;
                }
                else{
                    this.menuDataService.toggleMenuBar.next(true);
                    this.messageService.add({ severity: 'error', summary: 'Access Denied!', detail: 'No Access to Any Modules,Please Contact Admin!', sticky: false });
                    this.loaderEnable = false;
                }
            });
        }
        else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Session Expired!', sticky: false });
            this.loaderEnable = false;
            this.msalService.logout();
        }


        var that = this;
        this.menuDataService.toggleMenuBar.subscribe(function (data: any) {
            if (data && data != null) {
                that.visible = !that.visible;
            }
        });

        if (this.applicationStateService.getIsMobileResolution()) {
            this.visible = false;
        } else {
            this.visible = true;
        }

        var activeMenu = this.sessionService.getItem("active-menu");
        if (activeMenu) {
            this.selectedItem = activeMenu;
        } else {
            this.selectedItem = "Document Lookup";
        }
    }

    ngOnDestroy() {
        this.menuDataService.toggleMenuBar.observers.forEach(function (element) { element.complete(); });
    }

    sideBar() {
        this.visible = !this.visible;
    }

    // on menu click event
    onMenuClick(menu: CustomMenuItem) {
        // if child are available then open child
        if (menu.childs != undefined || menu.childs != null) {
            this.toggleSubMenu(menu);
            return;
        }
        if (menu.routerLink == undefined || menu.routerLink == null || menu.routerLink == "") {
            this.routeStateService.add("Error 404", "/error", null, false);
            return;
        }
        this.selectedItem = menu.label;
        this.sessionService.setItem("active-menu", menu.label);
        this.routeStateService.add(menu.label, menu.routerLink, null, true);
        // hide menu bar after menu click for mobile layout        
        setTimeout(() => {
            if (this.applicationStateService.getIsMobileResolution()) {
                this.visible = false;
            }
        }, 100);
    }

    // toggle sub menu on click
    toggleSubMenu(menu: CustomMenuItem) {
        menu.isChildVisible = !menu.isChildVisible;
    }

}
