<p-sidebar [visible]="visible" position="left" [showCloseIcon]="false" styleClass="np-menubar" [modal]="false">
    <ul class="np-menu">

        <ng-template #recursiveList let-list>
            <li *ngFor="let menu of list">
                <a (click)="onMenuClick(menu)" [ngClass]="{'active': selectedItem == menu.label}">
                    <i class="pi pi-list" [ngClass]="menu.icon"></i> {{menu.label | translate}}
                    <i class="pi right" *ngIf="menu.childs != null" [ngClass]="{'pi-chevron-down': !menu.isChildVisible,'pi-chevron-up': menu.isChildVisible }"></i>
                </a>
                <ul *ngIf="menu.childs && menu.childs.length > 0 && menu.isChildVisible" class="np-sub-menu">
                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: menu.childs }">
                    </ng-container>
                </ul>
            </li>
        </ng-template>
        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: items }"></ng-container>
    </ul>
</p-sidebar>


<div class="np-loader" *ngIf="loaderEnable">
    <p-progressSpinner strokeWidth="2"></p-progressSpinner>
</div>  