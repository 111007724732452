import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AuthGuard } from 'src/app/core/gaurds/auth.gaurd';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';
import { ErrorComponent } from './shared/error/error.component';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './features/home/home.component';


const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('src/app/features/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'register',
        loadChildren: () => import('src/app/features/register-user/register-user.module').then(m => m.RegisterUserModule)
    },
    {
        path: 'home',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
                canActivate: [MsalGuard]
            }
        ]
    },
    {
        path: 'datasource',
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: '/datasource/documents',
                pathMatch: 'full'
            },
            {
                path: 'documents',
                loadChildren: () => import('src/app/features/documents/documents.module').then(m => m.DocumentsModule),
                canActivate: [MsalGuard]
            },
            {
                path: 'settings',
                loadChildren: () => import('src/app/features/settings/settings.module').then(m => m.SettingsModule),
                canActivate: [MsalGuard]
            },
        ]
    },
    {
        path: 'error',
        component: ErrorComponent,
    },

    { path: '**', redirectTo: '/home' }
];  

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

